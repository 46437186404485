// Asynchronous form of getting ltik token
const getLtikPromise = new Promise((resolve, reject) => {
  const searchParams = new URLSearchParams(window.location.search);
  let potentialLtik = searchParams.get('id_token');
  if (!potentialLtik) {
    potentialLtik = sessionStorage.getItem('id_token');
    if (!potentialLtik) reject(new Error('Missing token.'));
  }
  resolve(potentialLtik);
});

const ltikPromise = new Promise((resolve, reject) => {
  getLtikPromise
    .then((res) => {
      sessionStorage.setItem('id_token', res);
      resolve(res);
    })
    .catch((err) => {
      reject(err);
    });
});

 //Synchronous form of getting ltik token
 const getLtik = () => {
   const searchParams = new URLSearchParams(window.location.search);
   console.log('the session storage is>>&&%%$$111234', sessionStorage)
   let ltik = searchParams.get('id_token');
   if (!ltik) {
     ltik = sessionStorage.getItem('id_token');
     if (!ltik) console.log('Missing id_token.');
   }
   sessionStorage.setItem('id_token', ltik);
   return ltik;
 };


 //Synchronous form of getting playbackMap
 const getPlayBackMap = (fileName) => {
  let searchedMap = localStorage.getItem(fileName);
  console.log('******inside get map>>>>>', localStorage)
  if (!searchedMap) {
    searchedMap = new Map();
    localStorage.setItem(fileName, JSON.stringify(Array.from(searchedMap.entries())));
  }else{
    searchedMap = new Map(JSON.parse(searchedMap));
  }
  return searchedMap;
};

//Synchronous form of getting playbackMap
const getRemainingMap = (fileName) => {
  let searchedMap = localStorage.getItem(fileName+'remaining');
  if (!searchedMap) {
    searchedMap = new Map();;
    localStorage.setItem(fileName+'remaining', JSON.stringify(Array.from(searchedMap.entries())));
  }else{
    searchedMap = new Map(JSON.parse(searchedMap));
  }
  return searchedMap;
};

//Synchronous form of getting playbackMap
const getFinishedMap = (fileName) => {
  let searchedMap = localStorage.getItem(fileName+'finished');
  if (!searchedMap) {
    searchedMap = new Map();;
    localStorage.setItem(fileName+'finished', JSON.stringify(Array.from(searchedMap.entries())));
  }else{
    searchedMap = new Map(JSON.parse(searchedMap));
  }
  return searchedMap;
};


//* LOCAL DEV TESTING
//const getLtik = () => {
  //return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwbGF0Zm9ybVVybCI6Imh0dHBzOi8vY2FudmFzLmluc3RydWN0dXJlLmNvbSIsImNsaWVudElkIjoiMTQ4MDkwMDAwMDAwMDAwMTIxIiwiZGVwbG95bWVudElkIjoiODcxOmM1NzIwYWE0YWU1YzAwY2VhNmNjYjhmNmEwOWY1YmJjNTQ4NWM3MjUiLCJwbGF0Zm9ybUNvZGUiOiJsdGlhSFIwY0hNNkx5OWpZVzUyWVhNdWFXNXpkSEoxWTNSMWNtVXVZMjl0TVRRNE1Ea3dNREF3TURBd01EQXdNVEl4T0RjeE9tTTFOekl3WVdFMFlXVTFZekF3WTJWaE5tTmpZamhtTm1Fd09XWTFZbUpqTlRRNE5XTTNNalUlM0QiLCJjb250ZXh0SWQiOiJodHRwcyUzQSUyRiUyRmNhbnZhcy5pbnN0cnVjdHVyZS5jb20xNDgwOTAwMDAwMDAwMDAxMjE4NzElM0FjNTcyMGFhNGFlNWMwMGNlYTZjY2I4ZjZhMDlmNWJiYzU0ODVjNzI1ODBhZWJlMzZlMWM2MDkzMTFlNDc4MjRkMTcxOWVlMWU4ZGYxNTJmN184MGFlYmUzNmUxYzYwOTMxMWU0NzgyNGQxNzE5ZWUxZThkZjE1MmY3IiwidXNlciI6IjQ3NDVmOTEwLTI1MTAtNDBmYS1iZGY0LTMwYTY4ODQ4NzM2ZSIsInMiOiJiZGQ1MjNhMmMxODYzOTM4MTY5OGFjOTZhZGUwZDhhYWI0NTJiNGRkMWRmNTQyZTQ1ZiIsImlhdCI6MTY3MTQ4NjMyM30.wg7MA09GcLVV4cGxyMzzRq5tiPn6UDZBT8TMqnJwwfk";
//};




export { ltikPromise, getLtik, getPlayBackMap, getRemainingMap, getFinishedMap };//{/*, getRemainingMap, getFinishedMap*/} };
